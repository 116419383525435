<!-- 布局 -->
<template>
	<div class='layout'>
		<!-- start 导航栏 -->
		<navbar></navbar>
		<!-- start 主体 -->
		<app-main></app-main>
		<!-- start 导航栏 -->
		<foot></foot>
	</div>
</template>

<script>
	import { Navbar, Foot, AppMain } from './components'
	export default{
		name: 'Layout',
		components: {
			Navbar,
			AppMain,
			Foot
		},
		
	}
</script>

<style >
	.layout{
		width: 1200px;
		height: 50px;
		margin: 0 auto;
	}
</style>