import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'index',
		title:'啊水水',
		redirect: '/index',
		component: Layout,
		children: [{
			path: '/index',
			component: () => import('@/views/index'),
			name: 'Index',
		},
		{
			path: '/product',
			component: () => import('@/views/product'),
			name: 'Product',
		},
		{
			path: '/case',
			component: () => import('@/views/case'),
			name: 'case',
		},
		{
			path: '/company',
			component: () => import('@/views/company'),
			name: 'company',
		},
		{
			path: '/contact',
			component: () => import('@/views/contact'),
			name: 'contact',
		},
		{
			path: '/other',
			component: () => import('@/views/other'),
			name: 'other',
		}]
	},
]

const router = new VueRouter({
	routes
})

export default router
