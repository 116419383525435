<template>
	<div class='navbar'>
		<!-- logo -->
		<div class="navbar_logo">
			<img src="@/assets/logo.jpg" alt="">
		</div>
		<!-- navderItem -->
		<div class="navbar_item">
			<ul v-for="(item, index) in navbarList" :key="index">
				<li :class="{'atcive': path == item.path}" @click="handleGoto(index,item.path)">{{ item.title }}</li>
			</ul>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				navbarList: [{
						title: '首页',
						path: '/index'
					},
					{
						title: '产品',
						path: '/product'
					},
					{
						title: '案例',
						path: '/case'
					},
					{
						title: '公司简介',
						path: '/company'
					},
					{
						title: '联系我们',
						path: '/contact'
					},
					{
						title: '其他资料',
						path: '/other'
					}
				],
				path: '/',
				input: ''
			}
		},
		created() {
			this.path = this.$route.path
		},
		methods: {
			handleGoto(index, path) {
				console.log('this。',this.$route);
				console.log('点击',path);
				const timestamp = (new Date()).valueOf()
				this.path = path
				this.$router.push({
					path: path,
					query: {
						sj: timestamp,
					}
				})
			},
		}
	}
</script>

<style scoped lang='less'>
	.atcive {
		background-color: #ff7e20;
		color: white;
	}

	.navbar {
		margin: 0 60px;
		display: flex;
		justify-content: space-between;

		&_logo {
			height: 50px;
			padding: 5px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		&_item {
			display: flex;

			ul {
				li {
					padding: 20px;
					float: left;
					list-style: none;
				}

				:hover {
					background-color: #ff7e20;
					color: white;
				}
			}

		}
	}
</style>
